.loadingWrapper {
	position: absolute;
	top: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
}

.loadingIcon {
	position: relative;
	width: 80px;
	height: 80px;
	background-color: #042e67;
	margin: 0 auto;
	top: 200px;
	border-radius: 10px;
	padding: 40px;
	opacity: 0.7;

}
.circleDiv {
	box-sizing: border-box;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: loadingIcon 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
	&:nth-child(1) {
		animation-delay: -0.6s;
	}
	
	&:nth-child(2) {
		animation-delay: -0.45s;
	}
	
	&:nth-child(3) {
		animation-delay: -0.30s;
	}
	&:nth-child(4) {
		animation-delay: -0.15s;
	}
}


.loadingChildren {
	position: absolute;
	top: 340px;
	width: 100%;
	text-align: center;
	display: block;
	height: 24px;
	line-height: 24px;
	background-color: #042e67;
	margin: 40px auto;
	opacity: 0.7;
	color: white;
	font-size: 1.2em;
}

@keyframes loadingIcon {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
