.top {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-top: 1px solid #D6D6D6;
}
.text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 125%; 
    color: #222;
}

.uploadButton {
    background-color: white !important;
}

.buttonText {
    margin-left: 8px;
}

.uploadNote {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 125%; 
    color: #6B6B6B;
}

.imageUploadInput {
    display: none;
}

.fileUploader {
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100%;
	.filesContainer {
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
		width: 100%;
        margin-top: 24px;
	}

	.filePlaceholder {
        border: 1px dashed #bbb;
		box-sizing: border-box;
		border-radius: 4px;
        height: 111px;
        width: 167px;
	}

	.imageUploadLabel {
		line-height: 24px;
		height: 40px;
		display: block;
		cursor: pointer;
		border: 2px dashed #e5e5e5;
		background-color: white;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 960px;
		box-sizing: border-box;
		margin-block: 16px;
		flex-grow: 0;
		font-size: 12px;
		padding: 20px;

		&_disabled {
			opacity: 0.7;
			cursor: not-allowed;
			pointer-events: none;
		}
		svg {
			margin-right: 8px;
			margin-left: 8px;
		}
		span {
			height: 40px;
			line-height: 40px;
		}
	}
}