.wrapper {
  flex: 0 1;
  position: relative;
}

.docItem {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 8px 8px 8px;
  background-color: white;
  height: 111px;
  width: 167px;
  align-items: center;
  gap: 16px;
}

.removeButton {
  position: absolute;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 3px;
  background: var(--theme-colour-white, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
    0px -0.5px 1px 0px rgba(0, 0, 0, 0.03);
  border: 0;
  cursor: pointer;
}

.fileName {
  line-height: 1.2em;
  text-align: center;
  color: var(--greys-grey-dark, #6b6b6b);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: felx;
  display: flex;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.fileNameText {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fileExtension {
  flex: 0;
}

.imageItem {
  position: relative;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  height: 111px;
  width: 167px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}
