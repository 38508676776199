body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.AlertContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  .contentWrapper {
    width: calc(100vw - 32px);
    box-sizing: border-box;
    max-width: 360px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4),
      0px -2px 8px 0px rgba(0, 0, 0, 0.1);

    font-weight: 600;
    color: #656565;
    text-align: center;
    line-height: 22px;
    font-size: 26px;
    word-break: break-word;
    // transform: translateY(100%);

    &.show {
      // transform: translateY(0);
    }

    .content {
      overflow: hidden;
      height: 100%;
      padding: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-align: left;
      background: #f6f6f6;
      border-top: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
    }
    .header {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
        color: #222;
        flex-grow: 1;
        text-align: left;
      }
      background-color: white;
    }
    .footer {
      padding: 16px;
      display: flex;
      background: white;
      gap: 16px;
    }
  }
}