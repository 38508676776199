.Alert {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000026;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
}
