.sendButtonContainer {
    border-top: 1px solid #D6D6D6;
    min-height: 72px;
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px
}

.sendingErrorText{
    color:  #DB0000;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; 
}